/* eslint-disable no-underscore-dangle */
import React, { useState } from 'react'
import PropTypes from 'prop-types'
import theme from '../../../../config/theme'
import shopify from '../../../../config/mapping/shopify'

import {
  ParagraphStyled,
  Container,
  FormContainer,
  Input,
  Button,
  Loadin,
  SuccessText,
  ErrorContainer,
  Error,
} from './styled'

const formLabel = 'Deine Auswahl ist leider ausverkauft. Wenn du uns deine Mailadresse hinterlässt, melden wir uns sobald unsere Lager wieder gefüllt sind!'
const regExp = /^(([^<>()[\]\\.,;:\s@"]+(.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

const EmailForm = ({ variants, activeVariation, title }) => {
  const [email, setEmail] = useState('')
  const [isSubmitted, setIsSubmitted] = useState(false)
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState('')

  const settings = {
    async: true,
    crossDomain: true,
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded',
      'cache-control': 'no-cache',
    },
  }
  const handleChange = (event) => {
    setEmail(event.target.value)
    setError('')
  }

  const validateEmail = (input) => {
    const testEmail = regExp.test(input)
    if (input === '') {
      return 'This field is required'
    }
    return testEmail || 'Please enter a valid email address'
  }

  const handleBlur = (evt) => {
    const { value } = evt.target
    const emailError = validateEmail(value)
    setError(emailError)
  }

  const handleSubmit = async (event) => {
    event.preventDefault()

    const body = new URLSearchParams()
    const timezoneOffset = new Date().getTimezoneOffset() / -60
    body.append('g', 'SP9Vcw')
    body.append('$fields', '$source,$email,$consent_method,$consent_form_id,$consent_form_version')
    body.append('$list_fields', '')
    body.append('$timezone_offset', timezoneOffset)
    body.append('$source', 'Back in Stock')
    body.append('$email', email)
    body.append('$consent_method', 'Klaviyo Form')
    body.append('$consent_form_id', 'Yd2PLj')
    body.append('$consent_form_version', 3315338)

    if (validateEmail(email) === true) {
      setIsLoading(true)
      try {
        const response = await fetch(
          'https://manage.kmail-lists.com/ajax/subscriptions/subscribe',
          {
            ...settings,
            body,
          }
        )
        const data = await response.json()
        if (data?.success) {
          window._learnq = window._learnq || []
          window._learnq.push([
            'identify',
            {
              $email: email,
            },
          ])
          setIsSubmitted(true)
          setIsLoading(false)
          shopify.sendDataLayer('subscribedBackInStock', variants[activeVariation], title)
        } else {
          setIsLoading(false)
        }
      } catch (e) {
        setIsLoading(false)
        // eslint-disable-next-line no-console
        console.warn('Request error', e)
      }
    } else {
      setError(validateEmail(email))
    }
  }

  return (
    <Container>
      {isSubmitted ? (
        <SuccessText>
          <p>Vielen Dank für deine Anmeldung</p> Bitte überprüfe deine E-Mails und bestätige die
          Anmeldung.
        </SuccessText>
      ) : (
        <>
          <ParagraphStyled font={theme.fonts.libreBaskerville}>{formLabel}</ParagraphStyled>
          <FormContainer onSubmit={handleSubmit}>
            <Input
              name="email"
              onChange={handleChange}
              onBlur={handleBlur}
              placeholder="E-Mail Adresse eingeben"
              error={!!error && error !== true}
            />
            <Button type="submit">{isLoading ? <Loadin /> : 'Anmelden'}</Button>
          </FormContainer>
          {!!error && error !== true && (
            <ErrorContainer>
              <Error>{error}</Error>
            </ErrorContainer>
          )}
        </>
      )}
    </Container>
  )
}

EmailForm.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        localFile: PropTypes.shape({}),
        originalSrc: PropTypes.string,
      }),
      price: PropTypes.string.isRequired,
      selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      variantId: PropTypes.string,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  title: PropTypes.string.isRequired,
  activeVariation: PropTypes.number.isRequired,
}
export default EmailForm
