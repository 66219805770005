import React from 'react'
import styled from 'styled-components'
import { palette, theme } from 'styled-tools'
import Container from '../Container'

import Heading from '../Heading'

export const ContainerStyled = styled(Container)`
  position: relative;
  padding: 3.5rem 2.5rem 2rem 1rem;
  ${theme('media.smUp')} {
    display: flex;
    align-items: center;
  }
`

export const Content = styled.div`
  padding-left: 0.5rem;
  line-height: 1.5;
  ${theme('media.smUp')} {
    display: flex;
    align-items: center;
    max-width: 40rem;
    &:before {
      display: block;
      width: 1px;
      height: 10rem;
      margin-right: 4rem;
      background-color: ${palette('black')};
      content: '';
    }
  }
`

export const HeadingStyled = styled((props) => <Heading {...props} />)`
  z-index: 1;
  width: 14rem;
  margin-bottom: 1rem;
  font-weight: bold;
  font-size: 2rem;
  line-height: 1.25;
  letter-spacing: 0.8px;
  ${theme('media.smUp')} {
    width: 20rem;
    font-size: 3rem;
  }
`

export const HeaderContainer = styled.div`
  display: flex;
`

export const IconContainer = styled.div`
  position: absolute;
  top: -4rem;
  right: 0;
  display: none;
  overflow: hidden;
  transform: translateX(1rem);

  ${theme('media.mdUp')} {
    top: -10%;
    right: unset;
    left: -5rem;
    display: block;
  }

  & svg {
    width: 8rem;

    ${theme('media.smDown')} {
      transform: translateX(2rem);
    }
  }
`
