import React, { useState } from 'react'
import PropTypes from 'prop-types'

import EmailForm from '../EmailForm'
import Button from '../../Button'
import Paragraph from '../../Paragraph'
import { priceFormatter } from '../../../../utils/formatter'

import { PriceWrapper } from '../styled'

const LoadedAction = ({
  available, onAddToCart, buttonInfo, variants, activeVariation, title,
}) => {
  const [isDisabled, setIsDisabled] = useState(false)

  if (!available) {
    return (
      <EmailForm {...{
        variants, activeVariation, title,
      }}
      />
    )
  }

  return (
    <Button
      onClick={() => {
        onAddToCart()
        setIsDisabled(true)
        setTimeout(() => setIsDisabled(false), 1000)
      }}
      disabled={isDisabled}
    >
      {buttonInfo}
    </Button>
  )
}

LoadedAction.propTypes = {
  available: PropTypes.bool.isRequired,
  onAddToCart: PropTypes.func.isRequired,
}

const PriceWrapperComponent = ({
  priceInfo,
  buttonInfo,
  price,
  variant,
  availabilityLoading,
  available,
  onAddToCart,
  insurance,
  variants,
  activeVariation,
  title,
}) => {
  if (!variant) {
    return (
      <PriceWrapper>
        <div>
          <Paragraph>Problem, Produkt zu laden!</Paragraph>
        </div>
        <Button disabled />
      </PriceWrapper>
    )
  }

  return (
    <PriceWrapper>
      <div>
        <Paragraph>{priceFormatter(insurance ? price + 2 : price)}€</Paragraph>
        <Paragraph>{priceInfo}</Paragraph>
      </div>
      {availabilityLoading ? (
        <Button disabled>lade Verfügbarkeit …</Button>
      ) : (
        <LoadedAction {...{
          available, onAddToCart, buttonInfo, variants, activeVariation, title,
        }}
        />
      )}
    </PriceWrapper>
  )
}

PriceWrapperComponent.propTypes = {
  ...LoadedAction.propTypes,
  ...EmailForm.propTypes,
  priceInfo: PropTypes.string.isRequired,
  buttonInfo: PropTypes.string.isRequired,
  variant: PropTypes.shape({}).isRequired,
  price: PropTypes.number.isRequired,
  availabilityLoading: PropTypes.bool.isRequired,
  insurance: PropTypes.bool,
}

PriceWrapperComponent.defaultProps = {
  insurance: false,
}

export default PriceWrapperComponent
