export const periodTypes = {
  DAILY: 'daily',
  WEEKLY: 'weekly',
  MONTHLY: 'monthly',
  YEARLY: 'yearly',
}

const addToCart = ({
  sku, subscription = false, period = periodTypes.MONTHLY, ...rest
}) => {
  const data = {
    sku,
    ...rest,
  }
  if (subscription) {
    data.subscription = subscription
    data.subscription_period = period
  }
  if (typeof window !== 'undefined' && window.location !== window.parent.location) {
    const obj = JSON.stringify({ type: 'ADD_TO_CART_EVENT', payload: process.env.GATSBY_CIRCULY_KEY || process.env.CIRCULY_KEY })
    window.parent.postMessage(obj, '*')
  }

  dispatchEvent(new CustomEvent('circuly-add-to-cart', { detail: data }))
}

export default addToCart
