import React from 'react'
import PropTypes from 'prop-types'
import RadioGroupStyled from './styled'

import RadioGroupContext from './context'

const RadioGroup = ({
  name, active, onSelect, children,
}) => (
  <RadioGroupContext.Provider
    value={{
      name,
      active,
      onSelect: (val) => onSelect(val),
    }}
  >
    <RadioGroupStyled>{children}</RadioGroupStyled>
  </RadioGroupContext.Provider>
)

RadioGroup.propTypes = {
  name: PropTypes.string.isRequired,
  active: PropTypes.string,
  onSelect: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
}

RadioGroup.defaultProps = {
  active: null,
  onSelect: () => {},
}

export default RadioGroup
