import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { StaticImage } from 'gatsby-plugin-image'

import Page from '../../components/layouts/Page'
import ProductHero from '../../components/parts/ProductHero'
import Container from '../../components/parts/Container'
import Columns from '../../components/parts/Columns'
import Column from '../../components/parts/Column'
import Paragraph from '../../components/parts/Paragraph'
import { ColumnHeading, GoBack } from './styled'
import { removeHtmlTags } from '../../utils/removehHmlTags'

const Accessory = ({
  variants,
  variantOptions,
  variantMapByOption,
  title,
  tags,
  description,
  earliestStartDate,
  isAccessory,
  metafields,
  featuredImage,
  theme,
}) => {
  const [startDate, setStartDate] = useState(earliestStartDate)
  const [isYearly, setIsYearly] = useState(false)
  const [hasInsurance, setHasInsurance] = useState(false)
  const contentTitle = metafields
    .filter((field) => field.namespace === 'accessories' && field.key === 'contentTitle')
    .map((field) => field.value)

  const contentMain = metafields
    .filter((field) => field.namespace === 'accessories' && field.key === 'contentMain')
    .map((field) => field.value)
  const contentImage = metafields
    .filter((field) => field.namespace === 'accessories' && field.key === 'contentImage')
    .map((field) => field.value)
  useEffect(() => {
    if (typeof window !== 'undefined' && window.localStorage.getItem('startDate') !== null) {
      setStartDate(new Date(window.localStorage.getItem('startDate')))
    }
    if (typeof window !== 'undefined' && window.localStorage.getItem('isYearly') !== null) {
      setIsYearly(window.localStorage.getItem('isYearly') === 'true')
    }
  }, [])

  return (
    <Page title={title}>
      <GoBack to="/zubehoer">
        <StaticImage
          src="../../static/images/icons/color-black.png"
          alt=""
          layout="constrained"
          objectFit="cover"
          objectPosition="center center"
          placeholder="tracedSVG"
        />
      </GoBack>
      <ProductHero
        subtitle="Der grünste Kinderwagen der Welt im Abo."
        priceInfo="im Monat"
        buttonInfo="In den Warenkorb"
        {...{
          title,
          tags,
          description,
          variantOptions,
          variants,
          variantMapByOption,
          earliestStartDate,
          startDate,
          setStartDate,
          setIsYearly,
          setHasInsurance,
          isYearly,
          hasInsurance,
          isAccessory,
          featuredImage,
          theme,
        }}
      />

      <Container id="details">
        <Columns gap>
          <Column>
            <img src={contentImage} alt="" style={{ width: '100%' }} />
          </Column>
          <Column>
            <ColumnHeading level={3} position="simple">
              {contentTitle}
            </ColumnHeading>
            <Paragraph weight="500">{removeHtmlTags(contentMain)}</Paragraph>
          </Column>
        </Columns>
      </Container>
    </Page>
  )
}

Accessory.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantOptions: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantMapByOption: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array.isRequired,
  description: PropTypes.string.isRequired,
  earliestStartDate: PropTypes.instanceOf(Date).isRequired,
  isAccessory: PropTypes.bool.isRequired,
  metafields: PropTypes.arrayOf(
    PropTypes.shape({
      namespace: PropTypes.string.isRequired,
      key: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
    })
  ).isRequired,
  featuredImage: PropTypes.shape({
    localFile: PropTypes.shape({}).isRequired,
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
  }).isRequired,
}

Accessory.defaultProps = {
  featuredImage: null,
}

export default Accessory
