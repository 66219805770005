import styled, { keyframes } from 'styled-components'
import { palette, theme } from 'styled-tools'

import Paragraph from '../../Paragraph'

export const Container = styled.div`
  width: 100%;
  padding: 1rem 2rem;
  background-color: ${palette('yellow', 1)};
`

export const FormContainer = styled.form`
  display: flex;
  width: 100%;
`

export const Input = styled.input`
  box-sizing: border-box;
  width: 75%;
  height: 3rem;
  padding: 0px 0px 0px 1rem;
  color: ${palette('black', 1)};
  font-weight: 400;
  font-size: 0.9rem;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  letter-spacing: 0px;
  text-align: left;
  background-color: ${palette('white')};
  border: 1px solid ${({ error }) => (error ? palette('red', 2) : palette('black', 1))};
  border-radius: 2px;
  
  ${theme('media.mdUp')} {
    font-size: 1rem;
  }

  &:focus {
    border: 1px solid ${palette('black')};
    outline: none;
    box-shadow: rgb(0 100 205) 0px 0px 5px;
  }
`
export const Button = styled.button`
  position: relative;
  align-self: flex-end;
  width: auto !important;
  min-width: 6.5rem;
  height: 3rem !important;
  color: ${palette('white')};
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 1;
  letter-spacing: 0px;
  white-space: normal;
  text-align: center;
  word-break: break-word;
  background: ${palette('black', 1)};
  border: 5px solid ${palette('black', 1)};
  border-radius: 0px;
  cursor: pointer;

  ${theme('media.mdUp')} {
    font-size: 1rem;
  }

  &:focus,
  &:hover {
    cursor: pointer;
  }
`

export const ParagraphStyled = styled(Paragraph)`
  margin-bottom: 0.875rem;
  font-weight: 500;
  font-size: 0.75rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.17;
  letter-spacing: 0.15px;
`
export const SuccessText = styled(Paragraph)`
  margin-bottom: 1rem;
  color: ${palette('black', 1)};
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.17;

  & p {
    margin-bottom: 0.3rem;
    font-weight: bold;
    font-size: 1.5rem;
  }
`
const spin = keyframes` 
0% {
  transform: rotate(0deg)
}

100% {
  transform: rotate(360deg)
}
`

export const Loadin = styled.div`
  width: 1.5rem !important;
  height: 1.5rem;
  margin: auto !important;
  padding: 0 !important;
  border: 3px solid #f3f3f3;
  border-top: 3px solid #555;
  border-radius: 50%;
  animation: ${spin} 2s linear infinite;
`
export const ErrorContainer = styled.div`
  position: relative;
  width: 100%;
`

export const Error = styled.div`
  position: absolute;
  right: 20%;
  width: auto !important;
  margin-top: 0.5rem !important;
  padding: 0.5rem !important;
  color: ${palette('red', 2)};
  font-size: 14px;
  font-family: Arial, 'Helvetica Neue', Helvetica, sans-serif;
  background-color: ${palette('red', 3)};
  border: 1px solid ${palette('red', 2)};
  border-radius: 4px;
  box-shadow: rgb(0 0 0 / 26%) 1px 1px 4px 0px;
  transition: all 0.5s ease-out;

  &:before,
  &:after {
    position: absolute;
    display: block;
    width: 0px;
    height: 0px;
    border-style: solid;
    content: '';
  }
  &:before {
    top: -18px;
    left: 7px;
    border-color: transparent transparent ${palette('red', 2)};
    border-width: 9px;
  }
  &:after {
    top: -16px;
    left: 8px;
    border-color: transparent transparent ${palette('red', 3)};
    border-width: 8px;
  }
`
