import styled from 'styled-components'
import { theme } from 'styled-tools'
import { Link } from 'gatsby'

import Heading from '../../components/parts/Heading'

export const GoBack = styled(Link)`
  position: absolute;
  z-index: 1;
  display: none;
  margin-left: 1rem;
  ${theme('media.smUp')} {
    display: block;
  }
`

export const ColumnHeading = styled(Heading)`
  margin-top: -1.5rem;
  margin-bottom: 2rem;
  font-weight: 700;
  font-size: 2rem;
  line-height: 1.2;
  letter-spacing: 1.25px;

  ${theme('media.smUp')} {
    margin-top: 15%;
    margin-left: ${(props) => (props.position === 'simple' ? '0' : '-25%')};
    font-size: ${(props) => (props.position === 'simple' ? '2.5rem' : '3rem%')};
  }

  & ~ p {
    padding-bottom: 2rem;
    line-height: 1.5;
    letter-spacing: 0.4px;
  }
`

export const FlippedColumnHeading = styled(ColumnHeading)`
  ${theme('media.smUp')} {
    width: 80%;
    margin-right: ${(props) => (props.position === 'simple' ? '0' : '-25%')};
    margin-left: auto;
    text-align: right;
    & ~ p {
      text-align: right;
    }
  }
`

export const StyledHeading = styled(ColumnHeading)`
  margin-bottom: 0;
  ${theme('media.smUp')} {
    margin-left: 0;
  }
`

export const FlippedHeading = styled(FlippedColumnHeading)`
  margin-bottom: 0;
  ${theme('media.smUp')} {
    margin-right: 0;
  }

  & ~ span {
    ${theme('media.smUp')} {
      margin-left: auto;
    }
  }
`

export const CardContainer = styled.div`
  ${theme('media.smUp')} {
    margin-top: -1.5rem;
    margin-left: -25%;
  }
`

export const Card = styled.div`
  padding: 2rem;
  ${theme('media.smUp')} {
    width: 90%;
  }
`

export const CardHeading = styled(Heading)`
  margin-bottom: 0.5rem;
  font-weight: bold;
  font-size: 2rem;
  font-family: ${theme('fonts.libreBaskerville')};
  line-height: 1.2;
  letter-spacing: 0.8px;
`

export const CardDescription = styled.div`
  font-weight: 700;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;
  letter-spacing: 0.4px;
  ${theme('media.smUp')} {
    margin-left: 2rem;
  }
`

export const ParagraphContainer = styled.div`
  padding-bottom: 2rem;
  font-weight: 500;
  font-size: 1rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 1.5;
  letter-spacing: 0.4px;
  text-align: left;

  ${theme('media.smUp')} {
    text-align: ${({ align }) => (align ? 'left' : 'right')};
  }

  & li {
    margin-left: 1rem;
    list-style: disc;
  }
`
