import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Hashes from 'jshashes'

import RadioStyled from './styled'
import RadioGroupContext from '../RadioGroup/context'

const MD5 = new Hashes.MD5()

const Radio = ({ value, children }) => {
  const { name, active, onSelect } = useContext(RadioGroupContext)

  const checked = value === active
  const inputId = MD5.hex(`radio_${name}_${value}`)

  return (
    <RadioStyled>
      <input
        type="radio"
        id={inputId}
        name={name}
        value={value}
        checked={checked}
        onChange={() => onSelect(value)}
      />
      <label htmlFor={inputId}>{children}</label>
    </RadioStyled>
  )
}

Radio.propTypes = {
  value: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}

export default Radio
