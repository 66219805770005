import React from 'react'
import PropTypes from 'prop-types'

import theme from '../../../config/theme'
import {
  ContainerStyled, Content, HeadingStyled, HeaderContainer, IconContainer,
} from './styled'
import StoneBg from '../StoneBg'
import Icon from '../../../static/images/icons/arrow-circle.svg'

const SectionSwitch = ({ children }) => (
  <StoneBg>
    <ContainerStyled>
      <HeaderContainer>
        <HeadingStyled level={2} font={theme.fonts.libreBaskerville}>
          Du kannst jederzeit wechseln!
        </HeadingStyled>
        <IconContainer>
          <Icon />
        </IconContainer>
      </HeaderContainer>
      <Content>{children}</Content>
    </ContainerStyled>
  </StoneBg>
)

SectionSwitch.propTypes = {
  children: PropTypes.node.isRequired,
}

export default SectionSwitch
