import styled from 'styled-components'

const RadioGroupStyled = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  gap: 0.75rem 0;
  margin: 0.5rem 0;
`

export default RadioGroupStyled
