import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import parse from 'date-fns/parse'
import { add, isAfter } from 'date-fns'

import shopify from '../config/mapping/shopify'
import Accessory from './product/Accessory'
import Configurator from './product/Configurator'
import MainProduct from './product/MainProduct'

const Product = ({
  data: {
    collection,
    accessory,
    accessories: { items },
  },
  pageContext: {
    templateName, isAccessory, config, slug,
  },
}) => {
  let products = []
  let metafields = []
  let description
  let title
  let tags
  let featuredImage

  if (isAccessory) {
    products = accessory
    description = accessory.description
    title = accessory.title
    tags = accessory.tags
    metafields = accessory.metafields
    featuredImage = accessory.featuredImage
  } else {
    products = collection.products
    metafields = collection.metafields
    description = collection?.seo?.description || collection.description || ''
    title = shopify.getCleanTitleFromShopifyTitle(collection?.title || '')
  }

  const variants = shopify.mergeVariants(isAccessory ? [accessory] : products, config)
  const variantOptions = shopify.getAllOptions(variants, config, slug)

  const variantMapByOption = shopify.getVariantMapByOptionOrder(
    { variants, variantOptions }, config
  )

  const metaStart = [...metafields]
    .filter((field) => field.namespace === 'abo' && field.key === 'start')
    .map((field) => field.value)
    .shift()
  let earliestStartDate = new Date()
  if (metaStart) {
    const tmpStart = parse(metaStart, 'dd.MM.yyyy', new Date())
    if (isAfter(tmpStart, earliestStartDate)) {
      earliestStartDate = tmpStart
    }
  }
  earliestStartDate = add(earliestStartDate, { days: 5, hours: 6 })

  const theme = shopify.getDynamicTheme(config.colors)

  // Remove SpecialAccessory "insurance" from the array
  // const filteredItems = items.filter((item) => !shopify.isSpecialAccessory(item))
  switch (templateName) {
    case shopify.mainProducts.MAIN_PRODUCT:
      return (
        <MainProduct
          {...{
            pageTitle: collection.seo.title || shopify.getCleanTitleFromShopifyTitle(collection.title || ''),
            title,
            description,
            variants,
            variantOptions,
            variantMapByOption,
            earliestStartDate,
            metafields,
            theme,
          }}
          accessories={items}
        />
      )

    case shopify.mainProducts.ACCESSORY:
      return (
        <Accessory
          {...{
            title,
            tags,
            description,
            variants,
            variantOptions,
            variantMapByOption,
            earliestStartDate,
            isAccessory,
            metafields,
            featuredImage,
            theme,
          }}
        />
      )

    case shopify.mainProducts.CONFIGURATOR:
      return (
        <Configurator
          {...{
            title: shopify.getCleanTitle(
              shopify.getTemplateName(shopify.getProductSlug(collection.title))
            ),
            description,
            variants,
            variantOptions,
            variantMapByOption,
            earliestStartDate,
            theme,
          }}
        />
      )

    default:
      return null
  }
}

Product.propTypes = {
  data: PropTypes.shape({
    collection: PropTypes.shape({
      title: PropTypes.string.isRequired,
      description: PropTypes.string.isRequired,
      seo: PropTypes.shape({
        title: PropTypes.string,
        description: PropTypes.string,
      }).isRequired,
      products: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      metafields: PropTypes.arrayOf(
        PropTypes.shape({
          namespace: PropTypes.string.isRequired,
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
    }),
    accessory: PropTypes.shape({
      title: PropTypes.string.isRequired,
      // eslint-disable-next-line react/forbid-prop-types
      tags: PropTypes.array.isRequired,
      description: PropTypes.string.isRequired,
      metafields: PropTypes.arrayOf(
        PropTypes.shape({
          namespace: PropTypes.string.isRequired,
          key: PropTypes.string.isRequired,
          value: PropTypes.string.isRequired,
        })
      ).isRequired,
      featuredImage: PropTypes.shape({
        localFile: PropTypes.shape({}).isRequired,
      }),
    }),
    accessories: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.shape({
    templateName: PropTypes.oneOf(Object.values(shopify.mainProducts)).isRequired,
    isAccessory: PropTypes.bool,
    slug: PropTypes.string,
    config: PropTypes.shape({
      options: PropTypes.shape({}),
      colors: PropTypes.shape({}),
      conditions: PropTypes.shape({}),
      payments: PropTypes.shape({}),
      types: PropTypes.shape({}),
      customOrders: PropTypes.shape({}),
    }),
  }).isRequired,
}

export default Product

export const query = graphql`
  query Product($id: String!, $nameRegExp: String!) {
    collection: shopifyCollection(id: { eq: $id }) {
      title
      description
      metafields {
        namespace
        key
        value
      }
      seo {
        title
        description
      }
      products {
        title
        tracksInventory
        totalInventory
        variants {
          title
          price
          shopifyId
          availableForSale
          inventoryQuantity
          inventoryPolicy
          selectedOptions {
            name
            value
          }
          image {
            localFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
              }
            }
          }
        }
      }
    }

    accessories: allShopifyProduct(
      filter: {
        tags: { regex: $nameRegExp }
        collections: { elemMatch: { title: { in: ["ACCESSORIES"] } } }
      }
    ) {
      items: nodes {
        ...SingleAccessory
      }
    }
    accessory: shopifyProduct(id: { eq: $id }) {
      title
      description
      tags
      shopifyId
      featuredImage {
        localFile {
          childImageSharp {
            gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
          }
        }
      }
      metafields {
        namespace
        key
        value
      }
      tracksInventory
      totalInventory
      variants {
        title
        price
        shopifyId
        availableForSale
        inventoryQuantity
        inventoryPolicy
        selectedOptions {
          name
          value
        }
        image {
          localFile {
            childImageSharp {
              gatsbyImageData(layout: FULL_WIDTH, placeholder: TRACED_SVG)
            }
          }
        }
      }
    }
  }
`
