import styled from 'styled-components'
import { theme, palette } from 'styled-tools'

const RadioStyled = styled.div`
  margin-right: 4px;
  margin-bottom: 4px;

  [type='radio'] {
    position: absolute;
    left: -9999px;
    width: 0;
    height: 0;
    visibility: hidden;
  }

  label {
    position: relative;
    display: inline-block;
    padding: 0.5rem 1rem;
    color: ${palette('black')};
    font-weight: bold;
    font-size: 1rem;
    font-family: ${theme('fonts.munkenSans')};
    line-height: 1.5;
    text-transform: uppercase;
    background-color: ${palette('transparent')};
    border: none;
    outline: none;
    cursor: pointer;
    transition: ${theme('transition.base')};
  }

  input:checked + label {
    background-color: ${palette('white')};
    box-shadow: 5px 5px 20px 0 rgba(55, 98, 104, 0.3);
  }

  input:not(:checked) + label:hover,
  input:not(:checked) + label:focus {
    box-shadow: 5px 5px 20px 0 rgba(55, 98, 104, 0.3);
  }
`

export default RadioStyled
