import styled from 'styled-components'
import { palette, withProp } from 'styled-tools'
import { rgba } from 'polished'

export const RadioGroup = styled.div`
  display: flex;
`

export const RadioItem = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
`

export const RadioButton = styled.input`
  z-index: 1;
  width: 1.5rem;
  height: 1.5rem;
  margin: 0;
  background-color: transparent;
  border-radius: 50%;
  cursor: pointer;
  opacity: 0;
`

export const RadioButtonLabel = styled.label`
  position: absolute;
  top: 0.5rem;
  left: 20%;
  width: 1.5rem;
  height: 1.5rem;
  border-color: ${(props) => palette(props.color)};
  border-style: solid;
  border-width: 7px;
  border-radius: 50%;
`

export const CheckedRadioButtonLabel = styled(RadioButtonLabel)`
  top: 0.25rem;
  left: 8%;
  width: 2rem;
  height: 2rem;
  border-width: 10px;
  box-shadow: 5px 5px 15px 0 ${withProp(palette('black'), (color) => rgba(color, 0.25))};
`
