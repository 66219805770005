import React, { useState } from 'react'
import PropTypes from 'prop-types'
import get from 'lodash.get'

import ProductHero from '../../components/parts/ProductHero'

const SUBTITLE = {
  Greentom: 'Der grünste Kinderwagen der Welt im Abo.',
  MountainBuggy: 'Der Offroad-Kinderwagen im Abo.',
  Woom: 'Die superleichten Kinderfahrräder von Woom im Abo.',
  BugabooFox2: 'Der Premium-Kinderwagen mit perfektem Handling',
  BugabooDonkey3: 'Das Raumwunder für Familien mit großen Plänen',
}

const Configurator = ({
  variants,
  variantOptions,
  variantMapByOption,
  title,
  description,
  earliestStartDate,
  theme,
}) => {
  const [startDate, setStartDate] = useState(earliestStartDate)
  const [, setIsYearly] = useState(false)
  const [, setHasInsurance] = useState(false)
  const cleanTitle = typeof title === 'string' && title.replace(/ /g, '')
  const subtitle = get(SUBTITLE, cleanTitle, '')

  return (
    <ProductHero
      subtitle={subtitle}
      priceInfo="im Monat, einmalig 20€ Anmeldegebühr"
      buttonInfo="Jetzt sicher zur Kasse"
      {...{
        title,
        description,
        variantOptions,
        variants,
        variantMapByOption,
        earliestStartDate,
        startDate,
        setStartDate,
        setIsYearly,
        setHasInsurance,
        theme,
      }}
    />
  )
}
Configurator.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantOptions: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantMapByOption: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  earliestStartDate: PropTypes.instanceOf(Date).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
  }).isRequired,
}

export default Configurator
