import React, {
  useState, useCallback, useEffect, useRef,
} from 'react'
import PropTypes from 'prop-types'
import { useEmblaCarousel } from 'embla-carousel/react'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import get from 'lodash.get'
import DatePicker from 'react-datepicker'
import add from 'date-fns/add'
import isValid from 'date-fns/isValid'
import { ThemeProvider } from 'styled-components'

import {
  ProductHeroContainer,
  CarouselWrapper,
  MainGrid,
  ContentWrapper,
  TextWrapper,
  FiltersWrapper,
  CheckboxContainer,
  ColorpickerContainer,
  Label,
  MainLabel,
  MoreDetails,
  DatePickerWrapper,
  Subtitle,
  Description,
  StyledCheckbox,
  AccessoriesTag,
} from './styled'

import Paragraph from '../Paragraph'
import Heading from '../Heading'
import Colorpicker from '../Colorpicker'
import RadioGroup from '../RadioGroup'
import Radio from '../Radio'
import PriceWrapper from './PriceWrapper'

import addToCart, { periodTypes } from '../../../utils/addToCart'
import shopify from '../../../config/mapping/shopify'

const getVariationSelector = (options) =>
  Object.values(options)
    .reduce((acc, curr) => `${acc}.${curr}`, '')
    .substr(1)
const getVariantIndex = (variantMapByOption, options) =>
  get(variantMapByOption, getVariationSelector(options), 0)

const circulyBaseUrl = `https://checkout.api.circuly.io/v1/${
  process.env.GATSBY_CIRCULY_KEY || process.env.CIRCULY_KEY
}`

const ProductHero = ({
  variants,
  variantMapByOption,
  title,
  subtitle,
  description,
  variantOptions,
  priceInfo,
  buttonInfo,
  earliestStartDate,
  startDate,
  setStartDate,
  setIsYearly,
  setHasInsurance,
  isAccessory,
  tags,
  featuredImage,
  theme,
}) => {
  const [options, setOptions] = useState(
    variantOptions.reduce(
      (acc, current) => ({
        ...acc,
        [current.title]: current.values[0].value,
      }),
      {}
    )
  )
  const [availabilityLoading, setAvailabilityLoading] = useState(true)
  const [available, setAvailable] = useState(false)
  const [insurance, setInsurance] = useState(false)
  const [rememberIsYearly, setRememberIsYearly] = useState(false)
  const [activeVariation, setActiveVariation] = useState(
    getVariantIndex(variantMapByOption, options)
  )

  const [emblaRef, emblaApi] = useEmblaCarousel({
    loop: true,
    draggable: false,
    speed: 10,
  })
  const scrollTo = useCallback((index) => emblaApi && emblaApi.scrollTo(index), [emblaApi])
  scrollTo(activeVariation)

  const updateOption = (key, value) => {
    const newOptions = {
      ...options,
      [key]: value,
    }
    setOptions(newOptions)

    const index = getVariantIndex(variantMapByOption, newOptions)
    scrollTo(index)
    setActiveVariation(index)
  }

  const { variantId } = variants[activeVariation]
  const controllerRef = useRef()

  const searchIfAvailable = async () => {
    const controller = new AbortController()

    if (controllerRef.current) {
      controllerRef.current.abort()
    }

    /*
    if (
      variants[activeVariation].inventoryQuantity <= 0
      && variants[activeVariation].inventoryPolicy === 'DENY'
    ) {
      setAvailable(false)
      setAvailabilityLoading(false)
    } else {
      */
    controllerRef.current = controller
    try {
      const res = await fetch(`${circulyBaseUrl}/productBySku/${variantId}`, {
        signal: controllerRef.current?.signal,
      })
      const data = await res.json()
      setAvailable(data.stock > 0)
      setAvailabilityLoading(false)
      controllerRef.current = null
    } catch (e) {
      // eslint-disable-next-line no-console
      console.warn('Request aborted', e)
    }
    // }
  }

  useEffect(() => {
    setAvailabilityLoading(true)
    if (
      isAccessory
      && typeof window !== 'undefined'
      && window.localStorage.getItem('isYearly') === 'true'
      && !rememberIsYearly
    ) {
      updateOption('Zahlung', 'jährlich')
      setRememberIsYearly(true)
    }
    setIsYearly(shopify.isYearly(variants[activeVariation]))
    searchIfAvailable()
    shopify.sendDataLayer('productView', variants[activeVariation], title)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVariation])
  const colorOptions = variantOptions.filter((option) => option.type === 'color').shift()
  return (
    <ProductHeroContainer>
      <CarouselWrapper ref={emblaRef} isAccessory={isAccessory}>
        <div className="embla__container">
          {!isAccessory || colorOptions
            ? variants.map(
              (variant) =>
                variant?.image?.localFile && (
                <GatsbyImage
                  image={getImage(variant.image.localFile)}
                  alt={variant.title}
                  key={variant.variantId}
                />
                )
            )
            : featuredImage?.localFile && (
            <GatsbyImage image={getImage(featuredImage.localFile)} alt="" />
            )}
        </div>
      </CarouselWrapper>

      <MainGrid>
        <ContentWrapper>
          <TextWrapper>
            <Heading level={1}>{title}</Heading>
            {isAccessory ? (
              tags.length === 1 && <AccessoriesTag>{tags[0]}</AccessoriesTag>
            ) : (
              <Subtitle>{subtitle}</Subtitle>
            )}
            <Description>{description}</Description>
            {!isAccessory
              && (
              <MoreDetails to="#details" aria-label="Mehr erfahren">
                Mehr erfahren
              </MoreDetails>
              )}
          </TextWrapper>

          <FiltersWrapper>
            {variantOptions.map((option) => (
              <React.Fragment key={option.title}>
                {option.type === 'single' && option.values && option.values.length > 1 && (
                  <div key={option.title}>
                    <Paragraph>{option.title}</Paragraph>
                    <RadioGroup
                      name={`${option.title}-ProductHero`}
                      active={options[option.title]}
                      onSelect={(value) => updateOption(option.title, value)}
                    >
                      {option.values.map(({ value, label }) => (
                        <Radio key={value} value={value}>
                          {label}
                        </Radio>
                      ))}
                    </RadioGroup>
                  </div>
                )}
                {option.type === 'color' && option.values && option.values.length > 1 && (
                  <ThemeProvider theme={theme}>
                    <ColorpickerContainer>
                      <Colorpicker
                        options={option.values}
                        onSelect={(value) => updateOption(option.title, value)}
                        name={option.title}
                        active={options[option.title]}
                      />
                    </ColorpickerContainer>
                  </ThemeProvider>
                )}
              </React.Fragment>
            ))}

            <DatePickerWrapper>
              <span>Wann soll dein Abo starten?</span>
              <DatePicker
                selected={startDate}
                onChange={(date) => isValid(date) && setStartDate(add(date, { hours: 4 }))}
                minDate={earliestStartDate}
                dateFormat="dd.MM.yyyy"
              />
            </DatePickerWrapper>
          </FiltersWrapper>
          {!isAccessory && (
            <CheckboxContainer>
              <MainLabel htmlFor="addInsurance">
                <StyledCheckbox
                  onClick={() => {
                    setInsurance(!insurance)
                    setHasInsurance(!insurance)
                  }}
                >
                  <input
                    id="addInsurance"
                    type="checkbox"
                    checked={insurance}
                    onChange={() => {}}
                  />
                  <label />
                </StyledCheckbox>
                <Label htmlFor="addInsurance">
                  <Paragraph>Diebstahlschutz</Paragraph>
                  <Paragraph>Abgesichert sind Diebstahl und Vandalismus.</Paragraph>
                </Label>
              </MainLabel>
            </CheckboxContainer>
          )}
        </ContentWrapper>

        <PriceWrapper
          {...{
            priceInfo,
            buttonInfo,
            availabilityLoading,
            available,
            insurance,
            variants,
            activeVariation,
            title,
          }}
          variant={variants[activeVariation]}
          price={shopify.getVariantPrice(variants[activeVariation])}
          onAddToCart={() => {
            const data = {
              sku: variants[activeVariation].variantId,
              subscription: true,
              subscription_start: startDate,
              period: shopify.isYearly(variants[activeVariation])
                ? periodTypes.YEARLY
                : periodTypes.MONTHLY,
            }
            addToCart(data)
            if (!isAccessory && window) {
              window.localStorage.setItem('startDate', startDate)
              window.localStorage.setItem('isYearly', shopify.isYearly(variants[activeVariation]))
            }
            if (insurance) {
              addToCart({
                ...data,
                sku: shopify.isYearly(variants[activeVariation])
                  ? '39594457333943'
                  : '39594457301175',
              })
            }
          }}
        />
      </MainGrid>
    </ProductHeroContainer>
  )
}

ProductHero.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      image: PropTypes.shape({
        id: PropTypes.string,
        localFile: PropTypes.shape({}),
        originalSrc: PropTypes.string,
      }),
      price: PropTypes.string.isRequired,
      selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
      shopifyId: PropTypes.string,
      variantId: PropTypes.string,
      availableForSale: PropTypes.bool,
      inventoryQuantity: PropTypes.number,
      inventoryPolicy: PropTypes.string,
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantMapByOption: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  variantOptions: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      type: PropTypes.oneOf(['single', 'color']),
      values: PropTypes.arrayOf(
        PropTypes.shape({
          label: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  subtitle: PropTypes.string,
  priceInfo: PropTypes.string,
  buttonInfo: PropTypes.string,
  earliestStartDate: PropTypes.instanceOf(Date).isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  setStartDate: PropTypes.func.isRequired,
  setIsYearly: PropTypes.func.isRequired,
  setHasInsurance: PropTypes.func.isRequired,
  isAccessory: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  tags: PropTypes.array,
  featuredImage: PropTypes.shape({
    localFile: PropTypes.shape({}).isRequired,
  }),
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
  }).isRequired,
}

ProductHero.defaultProps = {
  subtitle: null,
  priceInfo: null,
  buttonInfo: null,
  isAccessory: false,
  tags: null,
  featuredImage: null,
}
export default ProductHero
