/* eslint-disable react/no-danger */
import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Page from '../../components/layouts/Page'
import ProductHero from '../../components/parts/ProductHero'
import Container from '../../components/parts/Container'
import Paragraph from '../../components/parts/Paragraph'
import SectionSwitch from '../../components/parts/SectionSwitch'
import Columns from '../../components/parts/Columns'
import Column from '../../components/parts/Column'
import YellowBg from '../../components/parts/YellowBg'
import shopify from '../../config/mapping/shopify'

import {
  ColumnHeading,
  FlippedColumnHeading,
  Card,
  CardContainer,
  CardHeading,
  CardDescription,
  ParagraphContainer,
} from './styled'

import AccessoriesList from '../../components/parts/AccessoriesList'

const MainProduct = ({
  pageTitle,
  variants,
  variantOptions,
  variantMapByOption,
  title,
  description,
  accessories: items,
  earliestStartDate,
  metafields,
  theme,
}) => {
  const [startDate, setStartDate] = useState(earliestStartDate)
  const [isYearly, setIsYearly] = useState(false)
  const [hasInsurance, setHasInsurance] = useState(false)
  const productContent = shopify.getProductContent(metafields)
  const yellowContent = shopify.getYellowCardInfos(metafields)

  return (
    <Page title={pageTitle}>
      <ProductHero
        subtitle={shopify.getProductInfo(metafields, 'subtitle', 'content')}
        priceInfo={shopify.getProductInfo(metafields, 'price_info', 'content')}
        buttonInfo={shopify.getProductInfo(metafields, 'button_info', 'content')}
        {...{
          title,
          description,
          variantOptions,
          variants,
          variantMapByOption,
          earliestStartDate,
          startDate,
          setStartDate,
          setIsYearly,
          setHasInsurance,
          theme,
        }}
      />
      {items.length > 1 && (
      <AccessoriesList
        carousel
        {...{
          items,
          startDate,
          isYearly,
          hasInsurance,
        }}
      />
      )}
      {items.length <= 1 && <div style={{ height: '4rem' }} />}
      <Container id="details">
        {productContent.map((item, index) => {
          const renderHeading = index % 2 === 0 ? (
            <ColumnHeading level={3} position="simple">
              {item.heading}
            </ColumnHeading>
          ) : (
            <FlippedColumnHeading level={3} position="simple">
              {item.heading}
            </FlippedColumnHeading>
          )
          return (
            <Columns gap key={item.namespace} flipDesktop={index % 2 === 1}>
              <Column>
                <img src={item.image} alt="" style={{ width: '100%' }} />
              </Column>
              <Column>
                {renderHeading}
                <ParagraphContainer
                  align={index % 2 === 0}
                  dangerouslySetInnerHTML={{
                    __html: item.text,
                  }}
                />
              </Column>
              {index === productContent.length - 1 && (
                <>
                  <Column />
                  <Column>
                    <CardContainer>
                      <YellowBg>
                        <Card>
                          <CardHeading level={4}>{yellowContent.title}</CardHeading>
                          <CardDescription
                            dangerouslySetInnerHTML={{ __html: yellowContent.text }}
                          />
                        </Card>
                      </YellowBg>
                    </CardContainer>
                  </Column>
                </>
              )}
            </Columns>
          )
        })}
      </Container>

      <SectionSwitch>
        <Paragraph>
          Wir wollen, dass du immer den richtigen Kinderwagen für das Alter und die Bedürfnisse
          deines Kindes hast. Darum kannst du einmal pro Jahr kostenlos zwischen unseren Modellen
          wechseln. So startest du zum Beispiel mit dem Carrycot und wechselst nach 9 Monaten zum
          Buggy, weil dein Kind jetzt neugierig auf die Welt ist und sitzen kann!
        </Paragraph>
      </SectionSwitch>
    </Page>
  )
}

MainProduct.propTypes = {
  variants: PropTypes.arrayOf(
    PropTypes.shape({
      selectedOptions: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
          value: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantOptions: PropTypes.array.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  variantMapByOption: PropTypes.object.isRequired,
  title: PropTypes.string.isRequired,
  pageTitle: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  accessories: PropTypes.arrayOf(PropTypes.shape({})),
  earliestStartDate: PropTypes.instanceOf(Date).isRequired,
  metafields: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  theme: PropTypes.shape({
    palette: PropTypes.shape({}),
  }).isRequired,
}

MainProduct.defaultProps = {
  accessories: [],
}

export default MainProduct
