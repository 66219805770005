import React from 'react'
import PropTypes from 'prop-types'

import Paragraph from '../Paragraph'
import {
  RadioButton,
  RadioGroup,
  RadioItem,
  RadioButtonLabel,
  CheckedRadioButtonLabel,
} from './styled'

const RadioButtons = ({ active, onSelect, options }) =>
  options.map((option) => {
    const LabelComponent = active === option.value ? CheckedRadioButtonLabel : RadioButtonLabel

    return (
      <RadioItem key={option.value}>
        <RadioButton
          checked={active === option.name}
          name={option.name}
          type="radio"
          aria-label={option.label}
          onChange={() => onSelect(option.value)}
        />
        <LabelComponent color={option.value} />
      </RadioItem>
    )
  })

const Colorpicker = ({
  name, active, onSelect, options,
}) => (
  <div>
    <Paragraph styles={{ fontSize: '0.875rem', paddingBottom: '0.5rem' }}>{name}</Paragraph>
    <RadioGroup>
      <RadioButtons options={options} active={active} onSelect={onSelect} />
    </RadioGroup>
  </div>
)

Colorpicker.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    })
  ).isRequired,
  active: PropTypes.string,
  onSelect: PropTypes.func,
  name: PropTypes.string.isRequired,
}

Colorpicker.defaultProps = {
  active: null,
  onSelect: () => {},
}

export default Colorpicker
