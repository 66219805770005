import * as React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

const StoneBg = ({ children }) => {
  const data = useStaticQuery(graphql`
    {
      stoneBg: file(relativePath: { eq: "bg/stone.png" }) {
        childImageSharp {
          fluid(maxHeight: 374) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)
  const { stoneBg } = data

  return (
    <BackgroundImage
      fluid={stoneBg.childImageSharp.fluid}
      style={{
        backgroundRepeat: 'repeat',
        backgroundSize: '1450px 374px',
        marginBottom: '2rem',
      }}
    >
      {children}
    </BackgroundImage>
  )
}

StoneBg.propTypes = {
  children: PropTypes.node.isRequired,
}

export default StoneBg
