import styled from 'styled-components'
import { theme, palette, withProp } from 'styled-tools'
import { rgba } from 'polished'
import { Link } from 'gatsby'

import Paragraph from '../Paragraph'

export const ProductHeroContainer = styled.section`
  position: relative;
  display: flex;
  flex-flow: column;

  width: 100%;
  min-height: 84.5rem;
  background-color: ${withProp(palette('sage'), (color) => rgba(color, 0.6))};

  ${theme('media.mdUp')} {
    display: grid;
    grid-template-columns: 1fr 1.5fr;
    min-height: 48.75rem;
  }
`

export const MainGrid = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  justify-content: space-between;
  ${theme('media.mdUp')} {
    z-index: 2;
    display: grid;
    grid-template-rows: 1fr auto;
  }
`

export const ContentWrapper = styled.div`
  ${theme('media.mdUp')} {
    display: grid;
    grid-auto-rows: max-content;
    padding: 4rem 3.5rem;
  }
`

export const TextWrapper = styled.div`
  width: ${theme('container.width')};
  margin: auto;
  padding: 2rem 0 0;

  ${theme('media.mdUp')} {
    margin-left: unset;
    padding-top: 0;
  }

  & > h1 {
    font-weight: 700;
    font-size: 2rem;
    font-family: ${theme('fonts.libreBaskerville')};

    ${theme('media.mdUp')} {
      font-size: 3rem;
    }
  }
`

export const FiltersWrapper = styled.div`
  width: ${theme('container.width')};
  margin: auto;

  :first-child {
    margin-top: 2rem;
  }

  ${theme('media.smUp')} {
    display: grid;
    grid-template-columns: auto 1fr;
    gap: 0 3rem;

    :nth-child(1),
    :nth-child(2) {
      margin-top: 2rem;
    }

    ${theme('media.mdUp')} {
      width: 100%;
      margin: 0;
    }
  }

  & > div,
  & > label {
    margin: 0 0 1.5rem;
    font-size: 0.8rem;
    letter-spacing: 0.35px;

    ${theme('media.lgUp')} {
      margin: 0 0 1.75rem;
    }
  }
`
export const ColorpickerContainer = styled.div`
  display: inline-flex;
  width: 50%;
`

export const PriceWrapper = styled.div`
  grid-column: 2;
  width: 100%;
  color: ${palette('green')};
  background-color: ${palette('white')};
  ${theme('media.mdUp')} {
    position: relative;
    display: grid;
    grid-column: 1;
    grid-template-columns: 1fr 1.3fr;
    width: calc(100% + 3rem);
    margin-left: -3rem;
  }
  & div:first-child {
    width: ${theme('container.width')};
    margin: auto;
    padding: 2rem 0;
    ${theme('media.mdUp')} {
      width: 100%;
      margin-left: unset;
      padding: 1rem 3rem;
    }
    & p:first-child {
      margin-bottom: 0.5rem;
      font-weight: 700;
      font-size: 2rem;
      letter-spacing: 0.8px;
      ${theme('media.mdUp')} {
        font-size: 3rem;
        letter-spacing: 1.25px;
      }
      & > p {
        font-size: 0.8rem;
      }
    }
  }
  button {
    width: 100%;
    height: 6rem;
    ${theme('media.mdUp')} {
      height: auto;
    }
    &:hover {
      border-radius: 0;
      box-shadow: none;
    }
  }
`

export const CarouselWrapper = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  /* stylelint-disable */
  & div {
    position: relative;
    min-width: 100%;
    background-color: ${(props) => props.isAccessory && palette('sage', 1)};

    ${theme('media.tvUp')} {
      max-height: 52rem;
    }

    &:first-child {
      display: flex;
      height: 100%;
    }
  }
`

export const StyledCheckbox = styled.div`
  display: inline-block;
  > input {
    opacity: 0;
    position: absolute;
  }
  > input + label {
    position: relative;
    padding-left: 25px;
    cursor: pointer;
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 1px;
      width: 2.5rem;
      height: 2.5rem;
      background: white;
    }
    &:after {
      content: '';
      position: absolute;
      display: inline-block;
      transform: rotate(45deg);
      top: 5px;
      left: 14px;
      height: 25px;
      width: 12px;
      border-bottom: 4px solid white;
      border-right: 4px solid white;
    }
  }
  > input:not(:checked) + label {
    &:after {
      opacity: 0;
    }
  }
  > input:checked + label {
    &:before {
      background: ${palette('black', 1)};
    }
    &:after {
      opacity: 1;
    }
  }
`
export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  width: ${theme('container.width')};
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 2rem;

  ${theme('media.mdUp')} {
    margin: 0;
    padding-top: 2rem;
  }
`

export const Checkbox = styled.input`
  width: 2.5rem;
  height: 2.5rem;
  margin: 0;
  cursor: pointer;
`

export const MainLabel = styled.label`
  display: flex;
  cursor: pointer;
`
export const Label = styled.label`
  display: inline-block;
  margin-left: 2rem;
  border: none;
  cursor: pointer;
  & > :first-child {
    font-size: 0.875rem;
    line-height: 1.71;
  }
  & > :last-child {
    font-weight: bold;
    text-transform: uppercase;
  }
`

export const MoreDetails = styled(Link)`
  display: block;
  margin-top: 0.75rem;
  color: ${palette('green')};
  font-size: 0.75rem;
  font-family: ${theme('fonts.munkenSans')};
  line-height: 2;
  letter-spacing: 0.3px;
`

export const DatePickerWrapper = styled.label`
  display: flex;
  flex-direction: column;

  span {
    margin-bottom: 0.5rem;
    font-size: 1rem;
    font-family: ${theme('fonts.munkenSans')};
  }

  input {
    width: 15rem;
    padding: 8px;
    font-weight: bold;
    font-size: 1rem;
    font-family: ${theme('fonts.munkenSans')};
    border: none;
    outline: none;
  }
`

export const Subtitle = styled(Paragraph)`
  font-weight: bold;
  margin-top: 0.5rem;
  letter-spacing: 0.4px;
`

export const Description = styled(Paragraph)`
  line-height: 1.5;
  margin-top: 0.5rem;
  letter-spacing: 0.4px;
`
export const AccessoriesTag = styled.div`
  width: fit-content;
  margin: 1rem 0;
  padding: 0.4rem 1rem;
  color: ${palette('white')};
  font-weight: 700;
  font-size: 0.75rem;
  font-family: ${theme('fonts.munkenSans')};
  background-color: ${palette('green', 1)};
  border-radius: 5px;
`
